import PropTypes from 'prop-types'
import styled from 'styled-components'
import {For} from 'babel-plugin-jsx-control-statements'
import countries from '../../../../content/settings/countries.json'
import TextInput from '../TextInput'


const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${({theme}) => `${theme.colors.gray}20`};
  border-radius: 3px;
  background-color: ${({theme}) => `${theme.colors.lightGray}40`};
  height: 3.75rem;

  &:hover,
  &:focus-within {
    border: 1px solid ${({theme}) => `${theme.colors.gray}50`};
    background-color: ${({theme}) => `${theme.colors.lightGray}60`};

    select {
      cursor: pointer;
    }
  }
`

const StyledSelect = styled.select`
  opacity: 0.5;
  border: 0;
  background-color: ${({theme}) => `${theme.colors.lightGray}40`};
  padding: 0 1rem;
  height: 100%;
  line-height: 1.375rem;
  color: ${({theme}) => theme.colors.secondary};
  font-size: 0.875rem;
`

const StyledInput = styled(TextInput)`
  border: 0;

  &:hover,
  &:focus-within {
    border: 0;
  }
`

const PhoneInput = ({label, prefixName, numberName, prefixValue, numberValue, onChange, required, ...props}) => {
  const handleChange = (e) => {
    const {value} = e.target
    if (onChange) onChange(prefixName, value)
  }

  return (
    <StyledContainer required={required}>
      <StyledSelect
          name={prefixName}
          value={prefixValue}
          onChange={handleChange}
          {...props}
      >
        <For each="country" of={countries.countries} index="idx">
          <option key={idx} value={country.dial_code}>
            {country.code} ({country.dial_code})
          </option>
        </For>
      </StyledSelect>
      <StyledInput
          type="tel"
          name={numberName}
          value={numberValue}
          label={label}
          onChange={onChange}
      />
    </StyledContainer>
  )
}

PhoneInput.propTypes = {
  label: PropTypes.string.isRequired,
  prefixName: PropTypes.string.isRequired,
  numberName: PropTypes.string.isRequired,
  prefixValue: PropTypes.string,
  numberValue: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
}

export default PhoneInput
