import React from 'react'
import PropTypes from 'prop-types'
import {If} from 'babel-plugin-jsx-control-statements'
import styled, {css} from 'styled-components'
import {media, convertMDContent} from '../../../utils'
import BaseLink from '../../atoms/BaseLink'
import Typography from '../../atoms/Typography'


const StyledContactContainer = styled.div`
  padding: 3.75rem 0 0 10.625rem;

  ${media.down('mobile')(css`
    padding: 2rem 1.5rem;
  `)}
`

const StyledInformation = styled(Typography)`
  padding: 3.125rem 0;
`

const StyledLink = styled(BaseLink)`
  text-decoration: underline;
  font-weight: normal;
`

const StyledInvoiceInformation = styled(Typography)`
  padding: 3.125rem 0;
`

const StyledBusinessInformation = styled(Typography)`
  padding-bottom: 3.125rem;
  width: 50%;

  ${media.down('mobile')(css`
    padding-bottom: 5rem;
    width: 100%;
  `)}
`


const CompanyInformation = ({title, address, googleMapsLink, googleMapsText, invoiceInformation,
  businessInformation, ...props}) => {

  return (
    <StyledContactContainer {...props}>
      <Typography type="h3">{title}</Typography>
      <StyledInformation type="div" dangerouslySetInnerHTML={{__html: convertMDContent(address)}} />
      <If condition={googleMapsText && googleMapsLink}>
        <StyledLink to={googleMapsLink}>{googleMapsText}</StyledLink>
      </If>
      <StyledInvoiceInformation
          type="div"
          dangerouslySetInnerHTML={{__html: convertMDContent(invoiceInformation)}}
      />
      <StyledBusinessInformation>{businessInformation}</StyledBusinessInformation>
    </StyledContactContainer>
  )
}

CompanyInformation.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  googleMapsLink: PropTypes.string.isRequired,
  googleMapsText: PropTypes.string.isRequired,
  invoiceInformation: PropTypes.string.isRequired,
  businessInformation: PropTypes.string.isRequired,
}

export default CompanyInformation
